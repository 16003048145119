:root {
  /*** 模块背景色*/
  --color-b-01: #f0f7ff;
  --color-b-02: #d6e8ff;
  --color-b-03: #adceff;
  --color-b-04: #85b1ff;
  --color-b-05: #5c92ff;
  /*** 主题色、按钮*/
  --color-b-06: #3370ff;
  --color-b-07: #2152d9;
  /*** 背景色*/
  --color-g-01: #f7f9fa;
  /*** 边框/分割线*/
  --color-g-02: #f0f2f5;
  /*** 边框颜色*/
  --color-g-03: #e1e4eb;
  --color-g-04: #d3d7e0;
  --color-g-05: #b5b9bf;
  --color-g-06: #84868c;
  --color-g-07: #515359;
  --color-g-08: #232426;
}
